import tw, { styled } from 'twin.macro';
import { media } from '@app/styles';

export const Wrapper = styled.div`
  position: relative;
  margin-top: 4em;
  padding: 2.2em 0 5em;
`;

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  padding-left: 5px;
  max-width: 1024px;
  z-index: 2;

  h1 {
    ${(props: any) => props.theme.fonts.secondary}
    margin-bottom: 1.6em;
    font-size: 2em;
    font-weight: 400;
    text-align: center;
  }
`;

interface LightboxProps {
  animationState?: string;
}

export const Lightbox = styled.div<LightboxProps>`
  z-index: 25;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: background-color 200ms ease-in-out;

  background-color: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
      case 'exited':
        return 'rgba(0, 0, 0, 0)';
      case 'entered':
      case 'exiting':
        return 'rgba(0, 0, 0, 0.8)';
      default:
        return 'rgba(0, 0, 0, 0.8)';
    }
  }};

  .lightbox-image {
    max-height: 80vh;
    max-width: 95vw;
    border-radius: 3px;
    cursor: pointer;
    transition: all 200ms ease-in-out;

    opacity: ${({ animationState }) => {
      switch (animationState) {
        case 'entering':
        case 'exited':
          return '0';
        case 'entered':
        case 'exiting':
          return '1';
        default:
          return '1';
      }
    }};

    transform: scale(
      ${({ animationState }) => {
        switch (animationState) {
          case 'entering':
          case 'exited':
            return '0.85';
          case 'entered':
          case 'exiting':
            return '1';
          default:
            return '1';
        }
      }}
    );
  }

  .lightbox-caption {
    background-color: white;
    padding: 8px;
    border-radius: 3px;
    font-size: 0.8em;
    width: 95vw;
    text-align: center;
    margin: 5px auto 0 auto;
    transition: opacity 400ms ease-in-out;

    opacity: ${({ animationState }) => {
      switch (animationState) {
        case 'entering':
        case 'exited':
          return '0';
        case 'entered':
        case 'exiting':
          return '1';
        default:
          return '1';
      }
    }};
  }

  ${media.md`
    .lightbox-caption {
      padding: 10px;
      font-size: 0.9em;
      width: 80vh;
    }
  `}
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  width: 2em;
  height: 2em;
  background-color: #ffffff;
  border-radius: 2em;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
  padding: 5px;
  cursor: pointer;
`;

export const NextButtonContainer = styled.div`
  position: absolute;
  top: calc(50vh - 1em);
  right: 20px;
  z-index: 2;
  width: 2em;
  height: 2em;
  background-color: #ffffff;
  border-radius: 2em;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
  padding: 5px;
  cursor: pointer;
`;

export const PrevButtonContainer = styled.div`
  position: absolute;
  top: calc(50vh - 1em);
  left: 20px;
  z-index: 2;
  width: 2em;
  height: 2em;
  background-color: #ffffff;
  border-radius: 2em;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
  padding: 5px;
  cursor: pointer;
`;

export const StickyButtonWrapper = styled.div`
  position: -webkit-sticky;
  position: sticky;
  bottom: 30px;
  text-align: center;
  margin-top: 1.5rem;
`;

// new stuff
export const GalleryWrapper = styled.div`
  ${tw`col-start-3 col-span-8 grid grid-cols-12 gap-y-3 justify-items-center`}
`;

export const PhotoWrapper = styled.div`
  ${tw`col-span-12 lg:col-span-4`}

  img {
    ${tw`rounded-xl cursor-pointer`}
  }
`;
